'use client';
import { useEffect } from 'react';
import { ApplicationOrigin, useSessionStore } from 'app/stores/globalStore';

export default function SetApplicationOrigin({
  origin = undefined,
  comment,
}: {
  origin?: ApplicationOrigin;
  comment?: string | undefined;
}) {
  const { setCurrentOrigin, setAppointmentComment } = useSessionStore(
    state => state
  );

  useEffect(() => {
    if (origin || origin == ApplicationOrigin.Web) setCurrentOrigin(origin);
    if (comment) setAppointmentComment(comment);
    else setAppointmentComment('');
  }, []);

  return <></>;
}
